import Vue from 'vue'
import VueRouter from 'vue-router'


// import Home from './components/Home.vue'
const Home = () => import(/* webpackChunkName: "home" */ './components/general/Home.vue')
// import Report from './components/Report.vue'
const Report = () => import(/* webpackChunkName: "home" */ './components/general/Report.vue')
// import Login from './components/Login.vue'
const Login = () => import(/* webpackChunkName: "home" */ './components/general/Login.vue')


Vue.use(VueRouter);
export const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '*',
			redirect: '/'
		},
		{ 
			path: '/', 
			name: "Home",
			component: Home,
			meta: {
				requiresAuth: true
			} 
		},
		{ 
			path: '/login', 
			name:'Login', 
			component: Login,
			props: true
		},
		{ 
			path: '/report/:id', 
			name:'Report', 
			component: Report, 
			props: true,
			meta: {
				requiresAuth: true
			}
		},
	]
});


