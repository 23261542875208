// credentials
var firebaseConfig = {
  apiKey: "AIzaSyCly-U-OC4BtG5zdmlyqhlFM3jYg2I-QEQ",
  authDomain: "resolve-perc-country-reports.firebaseapp.com",
  projectId: "resolve-perc-country-reports",
  storageBucket: "resolve-perc-country-reports.appspot.com",
  messagingSenderId: "365879385470",
  appId: "1:365879385470:web:e4aca8993e0d0233e6672f"
};


// initialize app
import firebase from 'firebase/app';
firebase.initializeApp(firebaseConfig);


// auth
import 'firebase/auth';
const auth = firebase.auth()


// export
export {
  firebase,
  auth
}