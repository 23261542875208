import Vue from 'vue'
import App from './App.vue'

// import styles
import '@/styles/site.scss';

// prismic cms
import PrismicVue from 'prismic-vue';
Vue.use(PrismicVue, {});

// vuex
import { store } from './store/store.js';

// VueRouter
import { router } from './routes.js';

// firebase
import { auth } from './firebaseConfig.js'; 




// Auth guards on routes
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth && !auth.currentUser) {
    next({ name: 'Login', params: { toRoute: to } })
  } else {
    next()
  }
})

// Auth on app
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

