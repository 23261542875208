<template>
  <div class='nav' v-if='!printView'>
    <div class='nav__content'>

      <!-- BACK button -->
      <!-- wrapper -->
      <div v-if='!printView' class='back-button__wrapper flex flex-justify-between'>
        
        <!-- left items -->
        <div class='h4'>
          <router-link v-on:click.native='resetReportID' class='link link-home' :to="{name: 'Home'}">home</router-link><span v-if='reportID' class='is-color-primary'> > <span class='is-color-black'>{{ reportID }}</span></span>
        </div>

        <!-- middle items -->
        <div v-if='reportID && !isLoading'>
          <p class='h4 h4-callout' :class="{'is-red': numPagesOverflowing}">{{ numPagesOverflowing }} page(s) are too long</p>
        </div>

        <!-- right items -->
        <div class='flex flex-row h4'>
          <div v-if='reportID' class='pad-right'>
            <p v-if='isLoading'>...</p>
            <a v-else class='link' @click='updatePrismic'>refresh</a>
          </div>

          <Logout />  
        </div>
      </div>
            
      <!-- GRID for checking things -->
      <div class='page__width'>
        <div v-if='debugView' class='grid__guide__wrapper grid-12'>
          <div class='grid__guide' v-for='x in [1,2,3,4,5,6,7,8,9,10,11,12]' :key='"grid" + x'>{{x}}</div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>

import Logout from './Logout.vue';

import { mapState, mapMutations, mapActions } from "vuex";
import { auth } from '../../firebaseConfig.js';

import Prismic from 'prismic-javascript';


export default {
  components: {
    Logout
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['debugView', 'printView', 'reportID', 'numPagesOverflowing']),
  },
  methods: {
    ...mapMutations(['resetReportID']),
    ...mapActions(['getReportMetadata', 'getCountryMetadata']),
    async updatePrismic() {

      // firebase auth
      const user = auth.currentUser;
      if (user) {
        this.isLoading = true;
        
        // get api
        const api = await Prismic.api("https://resolvetest.cdn.prismic.io/api/v2");
        
        // timeout for appearance of "loading"
        setTimeout(() => {  
          // pull data
          this.getReportMetadata(api);
          this.getCountryMetadata(api);

          // reset
          this.isLoading = false;
          }, 200);
      } 
    },
  }
}
</script>