<template>
  <div class='link' @click='logout'>logout</div>
</template>


<script>
import { auth } from '../../firebaseConfig.js'; 
import { mapMutations } from "vuex";


export default {
  props: {
    toRoute: Object
  },
  data() {
    return {
      user: {   
        email: '',
        password: ''
      }
    };
  },
  methods: {
    ...mapMutations(['resetReportID']),
    async logout () {
        this.resetReportID();
        await auth.signOut();
        this.$router.push({ name: 'Login' });
    },
  }
};
</script>