<template>
  <div class='app'>
    <!-- <Logout /> -->
    <Nav />
    <router-view class='content' />    
  </div>
</template>



<script>
import Nav from './components/general/Nav.vue';
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: 'App',
  components: {
    Nav
  },
  async mounted() {
    // get data
    this.getVendorData();


    // set debug
    const urlParams = new URLSearchParams(window.location.search);
    const debugView = urlParams.get('debugView');
    if (debugView === 'true') {
      this.setDebugView(true)
    }
    const printView = urlParams.get('printView');
    if (printView === 'true') {
      this.setPrintView(true)
    }

  },
  computed: {
    ...mapState(['allCountryData', 'reportMetadata', 'countryMetadata', 'logos', 'overflowObj', 'reportID']),
    ...mapGetters(['reportLang', 'isTranslated', 'dataIsReady', 'countryData', 'numPagesOverflowing'])
  },
  methods: {
    ...mapMutations(['setDebugView', 'setPrintView', 'getVendorData']),
  },
}
</script>

