import Vue from 'vue';

// import vuex
import Vuex from 'vuex';
Vue.use(Vuex);

// prismic cms
import Prismic from 'prismic-javascript';

// d3
import { csv } from 'd3-fetch';


export const store = new Vuex.Store({
	state: {
		overflowObj: {},
		numPagesOverflowing: 0,
		debugView: false,
		printView: false,
		reportMetadata: {},
		countryMetadata: {},
		allCountryData: [],
		logos: [],
		reportID: '',
		w2Date: new Date(2020,7,1),
		w3Date: new Date(2021,0,1),
		w4Date: new Date(2021,7,1),
		countries: {
			// keys = languages (for print)
			// vals = list of countries in [id, print_name] format
			'English': [
        ['generic_en', 'Generic'],
        ['cameroon_en', 'Cameroon'],
				['cotedivoire_en', "Cote D'Ivoire"],
				['drc_en', 'DRC'],
				['egypt_en', 'Egypt'],
				['ethiopia_en', 'Ethiopia'],
        ['ghana_en', 'Ghana'],
        ['guineaconakry_en', 'Guinea Conakry'],
				['kenya_en', 'Kenya'],
        ['liberia_en', 'Liberia'],
        ['morocco_en', 'Morocco'],
        ['mozambique_en', 'Mozambique'],
        ['nigeria_en', 'Nigeria'],
        ['tunisia_en', 'Tunisia'],
        ['senegal_en', 'Senegal'],
        ['southafrica_en', 'South Africa'],
				['sudan_en', 'Sudan'],
				['uganda_en', 'Uganda'],
        ['zambia_en', 'Zambia'],
        ['zimbabwe_en', 'Zimbabwe']
			],
			'French': [
				['generic_fr', 'Generic'],
        ['cameroon_fr', 'Cameroon'],
				['cotedivoire_fr', "Cote D'Ivoire"],
				['drc_fr', 'DRC'],
				['guineaconakry_fr', 'Guinea Conakry'],
				['morocco_fr', 'Morocco'],
				['senegal_fr', 'Senegal'],
				['tunisia_fr', 'Tunisia'],
			],
			'Portuguese': [
				['mozambique_pt', 'Mozambique']
			]
    },
		dropObj: {
			supportAdherence_trust_institutions: ['egypt_en', 'egypt_fr', 'egypt_pt']
		}
	},
	getters: {
		dataIsReady: (state, getters) => {
			if ((Object.keys(state.reportMetadata).length > 0) && (Object.keys(state.countryMetadata).length > 0)) {
				return true
			}
			return false
		},
		reportLang: (state) => {
			return state.reportID.length >= 2 ? state.reportID.slice(-2) : ''
		},
		isTranslated: (state, getters) => {
			return getters.reportLang !== 'en'
		},
		countryData: (state) => {
			const name = state.reportID;
			
			let filteredData = {};
			state.allCountryData.forEach( x => {
					filteredData[x.metric_id] = x[name];
			})
			return filteredData
		},
	},
	mutations: {
		setDebugView: (state, debugStatus) => {
			state.debugView = debugStatus;
		},
		setPrintView: (state, printStatus) => {
			state.printView = printStatus;
		},
		setReportMetadata: (state, metadata) => {
			state.reportMetadata = metadata;
		},
		setCountryMetadata: (state, countryMetadata) => {
			state.countryMetadata = countryMetadata;
		},
		setAllCountryData: (state, allCountryData) => {
			state.allCountryData = allCountryData;
		},
		setLogos: (state, logos) => {
			state.logos = logos;
		},
		setReportID: (state, id) => {
			state.reportID = id;
		},
		resetReportID: (state) => {
			state.reportID = '';
			state.reportLang = '';
		},
		setOverflowObject: (state, {id, status}) => {
			// update obj
			if (id != undefined) {
				state.overflowObj[id] = status;
			}

			// calculate val
			const values = Object.values(state.overflowObj);
			state.numPagesOverflowing = values.filter(x => { return x }).length;
		}
	},
	actions: {
		updateOverflowObj({ commit }, obj) {
			commit('setOverflowObject', obj)
		},
		resetPrismicData({ commit }) {
			commit('setReportMetadata', {});
			commit('setCountryMetadata', {});
		},
		async getReportMetadata({commit, state }, api) {
			const lang = state.reportID.split('_')[1];
			const data = api.query([
						Prismic.Predicates.any('my.report_metadata.id', ['metadata_' + lang])
					], { lang : '*' }).then(function(response) {
						commit('setReportMetadata', response.results[0].data), function(err) {
					console.log("Something went wrong: ", err);
				};
			});
		},
		async getCountryMetadata({ commit, state }, api) {
			const data = api.query([
					Prismic.Predicates.any('my.country_metadata.id', [state.reportID])
				], { orderings : '[my.country_metadata.id]' }).then(function(response) {
				commit('setCountryMetadata', response.results[0].data), function(err) {
					console.log("Something went wrong: ", err);
				};
			});
		},
		async getAllCountryData({ commit, state }) {
			if (state.allCountryData.length === 0) {
				// const uri = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ1FVgtcmFvq_uKhZ-6-_b96r1lrDwmzw8_ENWqtjoRbVGrSDPd_r9qPGXUeY4m9iA5SqXDdnORTm3y/pub?gid=0&single=true&output=csv';
				const uri = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTsQ_z6QIPf2cBDttq8_65MmO595hZH0N1-aWuvP_YNwoDx_fZ_vDNYEXQAWwyrzd9GaCaQEG16Zvur/pub?gid=0&single=true&output=csv';
				const csvData = await csv(uri);
				commit('setAllCountryData', csvData);
			}
		},
		async getLogos({ commit, state }, api) {
			if (state.logos.length === 0) {
				const data = api.query([
						Prismic.Predicates.at('document.type', 'logos' )
					]).then(function(response) {
					commit('setLogos', response.results[0].data)
				}, function(err) {
					console.log("Something went wrong: ", err);
				});
			}
		}
	}
});

